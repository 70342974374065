import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/crm-online-do-prowadzenia-firmy.png";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "Zarzadzanie-zadaniami-w-CRM.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: { eq: "prosty-crm-szanse-sprzedazy-quickcrm.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(relativePath: { eq: "aplikacja-crm-online-quickcrm.png" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: { eq: "prosty-crm-Zarzadzanie-uprawnieniami.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(relativePath: { eq: "system-crm-Baner-testuj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const CRMonline = ({ data }) => {
  return (
    <Artykul
      title="CRM online do prowadzenia firmy"
      articleImage={obrazekArtykulu}
      keywords={["crm online"]}
      articleImageAlt="crm online do prowadzenia firmy"
      metaTitle="CRM online do prowadzenia firmy w chmurze."
      metaDescription="✅ CRM online to skuteczne rozwiązanie mobilne dla osób, które szukają wsparcia w prowadzeniu firmy. QuickCRM, prosty system CRM dla dynamicznych zespołów."
    >
      <p>
        Trudno wyobrazić sobie prowadzenie firmy bez wsparcia ze strony
        technologii. Współcześnie każdy przedsiębiorca, mniej lub bardziej,
        korzysta z rozwiązań elektronicznych. Może to być prosty program do
        fakturowania, zwyczajny Excel czy inne narzędzie, wykorzystywane chętnie
        przez osoby prowadzące małą działalność. Jednak aby odnieść sukces
        należy wychodzić naprzeciw oczekiwaniom rynku i wybierać rozwiązania,
        które pozwolą być o krok przed konkurencją. CRM online to oprogramowanie
        internetowe, którego główną rolą jest zarządzanie relacjami z klientem.
        To bardzo szerokie stwierdzenie, więc zastanówmy się przez chwilę, jak
        CRM online może wpłynąć na poprawę funkcjonowania małego
        przedsiębiorstwa.
      </p>
      <h2>Baza wiedzy o klientach</h2>
      <p>
        CRM online pozwala na zebranie wszystkich informacji, dotyczących
        klientów Twojej firmy, w jednym miejscu. To oznacza koniec chaosu
        papierowych notatek czy nieporęcznych zeszytów. CRM online pozwala Ci
        mieć dostęp do bazy danych z każdego miejsca na Ziemi, ponieważ cały
        system działa w oparciu o chmurę, a interfejs jest dostępny z poziomu
        przeglądarki internetowej. Do każdego klienta przypisuje się osobę
        kontaktową oraz opiekuna – pracownika Twojej firmy, który jest
        odpowiedzialny za obsługę tej konkretnej osoby. Baza w systemie CRM
        pozwala też dotrzeć z łatwością do całej historii współpracy i
        powiązanych z klientem zadań. Prowadzenie firmy jest dużo łatwiejsze,
        jeśli masz dostęp do najważniejszych informacji w jednym miejscu.
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Testuj System CRM"
          alt="Testuj system CRM online"
          fluid={data.zdjecie1.childImageSharp.fluid}
        />
        Zarządzanie zadaniami w CRM
      </p>
      <h2>Planowanie pracy w CRM online</h2>
      <p>
        Wciąż korzystasz z papierowego kalendarza? Są one jeszcze w użyciu,
        chociaż stają się coraz mniej popularne. Wyobraź sobie teraz, że taki
        kalendarz przenosi się do sieci i możesz go dzielić z innymi
        pracownikami. Kilkoma kliknięciami możesz przydzielić zadania na
        konkretny dzień, lub przy niewielkim wysiłku zaplanować pracę handlowca
        na cały następny tydzień, bez konieczności wizyty w siedzibie czy
        instalowania programu na komputerze. Brzmi pięknie? To nie wszystko.
        Każde z zadań może być bowiem przypisane do danego kontrahenta, co
        pozwala dokładnie kontrolować to, na jakim etapie jest współpraca.
        Jednak to nie zadania są najważniejsze przy dbaniu o proces sprzedaży.
        Najpotężniejsze narzędzie, jakim dysponuje CRM online to szanse
        sprzedaży.
      </p>

      <h2>Szanse sprzedaży – czyli jak sprzedawać więcej, taniej i łatwiej</h2>
      <p>
        Nawet mała firma potrzebuje wsparcia w sprzedaży. Chcesz poprawić wyniki
        handlowców? CRM online umożliwia zarządzanie szansami sprzedaży. To
        narzędzie, które pozwala szybko analizować i oceniać każdy proces
        sprzedażowy w firmie. Polega to na obrazowaniu tego procesu za pomocą
        interaktywnych paneli, które wędrują po kolejnych etapach, pomagając
        sprzedawcy lepiej panować nad daną szansą sprzedaży.
      </p>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="Testuj System CRM"
        alt="Testuj System CRM online"
        fluid={data.zdjecie2.childImageSharp.fluid}
      />
      <h2>Jak to działa?</h2>
      <p>
        Otrzymując zapytanie o produkt lub usługę, zakładasz szansę sprzedaży i
        dodajesz do niej informacje o osobie klienta, potencjalnej wartości
        transakcji i planowanym terminie zamknięcia. Trafia ona na pierwszy etap
        procesu (możesz tworzyć indywidualne kampanie, a w nich określać różne
        etapy). Na każdym etapie handlowiec musi osiągnąć konkretne cele, aby
        szansa przeszła do następnego. Całość odbywa się za pomocą metody
        drag-and-drop, a więc praca jest łatwa i intuicyjna. Na koniec szansę
        oznacza się jako wygraną lub straconą, a w przypadku utraty należy podać
        przyczynę.
      </p>

      <h2>Mobilny wymiar biznesu</h2>
      <p>
        Narzędzie, jakim jest CRM online, pozwala trzymać rękę na pulsie nawet w
        najmniej sprzyjających warunkach. Wyobraź sobie sytuację, w której jadąc
        do pracy napotykasz olbrzymi korek lub inne utrudnienie, które opóźnia
        dojazd. Dzięki dostępowi z telefonu, możesz przydzielić zadania i
        skontaktować się z pracownikami przez komunikator i przełożyć planowane
        spotkanie na później bez wychodzenia z samochodu. Taki scenariusz
        pokazuje pewną zależność. Im bardziej mobilny jesteś, tym więcej
        zyskujesz.
      </p>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="Testuj System CRM"
        alt="Testuj system CRM online"
        fluid={data.zdjecie3.childImageSharp.fluid}
      />

      <p>
        Uwolnij się od telefonów Męczą Cię przeciągające się telefony? Każdy
        pracownik, który chce coś załatwić, zadzwoni akurat w złym momencie?
        Spędzasz przy telefonie całe dnie? CRM online umożliwi Ci ograniczenie
        tych rozmów do minimum, ponieważ wszystkie ustalenia są zapisane w bazie
        i możesz w każdej chwili do nich wrócić. Dzięki komunikatorowi i
        kalendarzowi z zadaniami Twoi pracownicy zawsze będą wiedzieć co mają
        zrobić, a Ty będziesz spokojniejszy. CRM online to skuteczne rozwiązanie
        mobilne dla osób, które szukają wsparcia w prowadzeniu firmy. Jego
        narzędzia pozwalają koordynować i planować pracę, ułatwiają komunikację
        wewnątrz i na zewnątrz firmy oraz dają możliwość raportowania efektów
        pracy.
      </p>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="Testuj System CRM"
        alt="Testuj system CRM online"
        fluid={data.zdjecie5.childImageSharp.fluid}
      />
    </Artykul>
  );
};

export default CRMonline;
